<template>
<div id="page-user-view">
    <vs-row vs-justify="center">
        <vs-col vs-justify="center" vs-align="center" class="pr-3" vs-w="8">
            <vx-card title="Basic Information" class="m-2" v-bind:customTitle="custData.firstGivenName + ' ' + custData.familyName" v-bind:subtitle="custData.fileNumber">
                <template slot="actions">
                    <vs-button color="primary" @click="editSelected()" class="mr-2">Edit</vs-button>
                    <vs-button color="dark" @click="goBack()">Back</vs-button>
                </template>
                <div class="vx-row">
                    <div class="vx-col flex-1">
                        <table>
                            <tr>
                                <td class="font-semibold">Title:</td>
                                <td>{{ custData.title }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">First Given Name:</td>
                                <td>{{ custData.firstGivenName }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Family Name:</td>
                                <td>{{ custData.familyName }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Other Name:</td>
                                <td>{{ custData.otherGivenNames }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Date of Birth:</td>
                                <td>{{ custData.dateofBirth | formatLongDate  }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </vx-card>
            <vx-card title="Case Information" class="m-2">
                <div class="vx-row">
                    <div class="vx-col flex-1" id="account-info-col-1">
                        <table>
                            <tr>
                                <td class="font-semibold">File Number:</td>
                                <td>{{ custData.fileNumber }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Clearance Type:</td>
                                <td>{{ custData.clearanceType }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Level:</td>
                                <td>{{ custData.level }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="vx-col flex-1" id="account-info-col-2">
                        <table>
                            <tr>
                                <td class="font-semibold">Clearance Status:</td>
                                <td>{{ custData.clearanceStatus }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Department Type:</td>
                                <td>{{ custData.clientDepartment }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">CSID:</td>
                                <td>{{ custData.vetteeId }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </vx-card>
            <vx-card title="Other Information" class="m-2">
                <div class="vx-row">
                    <div class="vx-col flex-1" id="account-info-col-1">
                        <table>
                            <tr>
                                <td class="font-semibold">Location Type:</td>
                                <td>{{ custData.locationType }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Request Received:</td>
                                <td>{{ custData.requestReceived  | formatLongDate }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Priority Due Date:</td>
                                <td>{{ custData.priorityDueDate  | formatLongDate }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Extension Return Date:</td>
                                <td>{{ custData.extensionReturnDate  | formatLongDate }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Completed:</td>
                                <td>{{ custData.completed  | formatLongDate }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Date Of Disposal:</td>
                                <td>{{ custData.dateOfDisposal  | formatLongDate }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Reason Over Benchmark:</td>
                                <td>{{ custData.reasonOverBenchmark }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="vx-col flex-1" id="account-info-col-2">
                        <table>
                            <tr>
                                <td class="font-semibold">Location Notes:</td>
                                <td>{{ custData.locationNotes }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Required Return Date:</td>
                                <td>{{ custData.requiredReturnDate  | formatLongDate }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Priority Met:</td>
                                <td class="px-3 py-2">
                                    <vs-checkbox disabled="true" v-model="custData.priorityMet"/>
                                </td>

                            </tr>
                            <tr>
                                <td class="font-semibold">Extension Approved:</td>
                                <vs-checkbox disabled="true" v-model="custData.extensionApproved"/>
                            </tr>
                            <tr>
                                <td class="font-semibold">Invoiced:</td>
                                <td>{{ custData.invoiced  | formatLongDate }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </vx-card>
        </vs-col>
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="4" class="pr-3">
            <vx-card title="Comments" class="m-2">
                <ViewCommentsVue :customerId="selectedId"></ViewCommentsVue>
            </vx-card>
        </vs-col>
    </vs-row>
</div>
</template>

<script>
import {
    customerData
} from '../../store/api/customer';

import VxCard from '../../components/vx-card/VxCard.vue';
import ViewCommentsVue from './ViewComments.vue';

export default {
    data() {
        return {
            custData: {},
            selectedId: ""
        }

    },
    components: {
        VxCard,
        ViewCommentsVue
    },
    async created() {
        this.selectedId = this.$route.params.id;
        this.loadCustomerDetails(this.selectedId);
    },
    methods: {
        async loadCustomerDetails(customerId) {
            this.custData = await customerData.getCustomerViewById(customerId);
        },
        async editSelected() {
            this.$router.push(`/admin/Customers/edit/${this.selectedId}`);
        },
        async goBack() {
            this.$router.back();
        }
    }
}
</script>

<style lang="scss">
#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 60px;
            padding-bottom: .8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width:370px) {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }
}
</style>
